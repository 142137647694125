.footerLink a
{
    color: #FFFFFF;
}
.footerLink a:visited
{
    color: #FFFFFF;
}
.footerLink a:hover
{
    color: #FFFFFF;
}
.footerLink button
{
    color: #FFFFFF;
}
.footerLink button:hover
{
    color: #FFFFFF;
}
