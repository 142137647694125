span.regular a:hover {
	color: #EEC643;
}

a:hover {
	/*color: #EEC643;*/
}
h1, h2 {
	display: block;
	height: 50px;
	font-size: 20px;
	padding-left: 20px;
	padding-top: 10px;
	font-weight: 400;
  color: #FFFFFF;
}
h2 {
	clear: both;
	margin-top: 40px;
	color: #EEC643;
	font-size: 20px;
	padding-left: 20px;
	padding-top: 10px;
	font-weight: 400;
}
h4 {
	margin-left: 20px;
	font-size: 20px;
	font-weight: 400;
  color: #FFFFFF;
}
p {
	font-size: 14px;
	line-height: 19px;
	margin-left: 20px;
	max-width: 580px;
	font-weight: 200;
}
.btn_blue {
	text-align: center;
	background: #7B98AB;
	-webkit-border-radius: 2;
	-moz-border-radius: 2;
	border-radius: 2px;
	color: #ffffff;
	font-size: 14px;
	margin-top: 20px;
	padding: 10px 20px 10px 20px;
	text-decoration: none;
	display: block;
	float: left;
}
.btn_blue:hover {
	color: #ffffff;
	background: #6C8392;
}
.bildbreak {
	float: left;
	margin-left: 20px;
	margin-right: 20px;
	margin-bottom:20px;
}
.bildflow {
	float: left;
	margin-left: 5px;
	margin-right: 5px;
	margin-bottom:20px;
}
.section {
	width: 50%;
	min-width: 500px;
	float: left;
	margin-bottom: 40px;
}


@media (max-width:580px) {
	.bildbreak {
	float: none;
margin:0 auto 0 auto;
	display: block;}
	.btn_blue {margin:0 20px 0 20px;display: block;text-align: center;width:82%; float:none;}
}
