
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Raleway-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'Raleway-Italic';
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/Raleway-Italic.ttf") format('truetype');
}

@font-face {
  font-family: 'Raleway-Bold';
  font-style: bold;
  font-weight: 700;
  src: url("./assets/fonts/Raleway-Bold.ttf") format('truetype');
}

@font-face {
  font-family: 'Raleway-Bold-Italic';
  font-style: italic;
  font-weight: 700;
  src: url("./assets/fonts/Raleway-BoldItalic.ttf") format('truetype');
}

@font-face {
  font-family: 'Raleway-Medium';
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/Raleway-Medium.ttf") format('truetype');
}

@font-face {
  font-family: 'Raleway-Italic';
  font-style: italic;
  font-weight: 500;
  src: url("./assets/fonts/Raleway-MediumItalic.ttf") format('truetype');
}

@font-face {
  font-family: 'Raleway-Light';
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/Raleway-Light.ttf") format('truetype');
}

@font-face {
  font-family: 'Raleway-Light-Italic';
  font-style: italic;
  font-weight: 300;
  src: url("./assets/fonts/Raleway-LightItalic.ttf") format('truetype');
}

div {
  letter-spacing: 0.45px;
}
input {
  letter-spacing: 0.45px;
}
span{
  letter-spacing: 0.45px;
}
.light {color: #FFFFFF; font-weight: 300;}
.regular {color: #FFFFFF; font-weight: 400;}
.medium { color: #FFFFFF; font-weight: 500; }
.mediumItalic { color: #FFFFFF; font-weight: 500; font-style: italic;}
.bo { color: #FFFFFF; font-style: bold;
font-weight: 700;}

p{color: #FFFFFF;
  letter-spacing: 0.45px;}

a{ color: #7B98AB;
letter-spacing: 0.45px;
text-decoration: none; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway';
  background:#00375D ;
  height: 100%;
}

html {
  height: 100%;
}

div#root {
  height: 100%;
}
/*IE11: disable X on inputfields*/
input[type=text]::-ms-clear{
  display:none;
}
/*IE11: disable ellipsis (...) */
input[type=text] {
  text-overflow: 'clip',
}
/*IE11: disable ellipsis (...) */
td{
  text-overflow: 'clip',
}
