
/*{ margin:0; box-sizing: border-box; }/* Quick reset */
.wrapper {
    background:#00375D ;
    margin:0 auto;
    overflow-y: hidden;
    height: 100%;

    /* Direction of the items, can be row or column */
    flex-direction: column;
}
.wrapperContent {
  display: flex;
  flex-direction: column;
  /*https://stackoverflow.com/questions/37905749/flexbox-not-working-in-internet-explorer-11*()*/
  /*min-height: 100vh; */
  height: 100%;
}

main
{
  flex: 1;
  display: flex;
  width: 100%;
}

header
{
  height: 70px;
  background: #00375D;
  min-width: 1105px;
}

footer
{
  background: #00375D;
}

.side {
  /*max-width: 1450px;*/
}
.content {
    /*max-width: 1300px;*/
    min-width: 925px;
    /* Margin entfernt wegen Farbverlaeufen
    margin-left: 10px;*/
    height: inherit;
    width: inherit;
}
.logoutContent {
  width: fill;
  /*max-width: 1450px;*/
}
.sidebar{
    background: #002C4A;
    min-width: 180px;
    height: inherit;
}


.waitDialogDiv
{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  z-index: 1000;
  display: flex;
}
.waitDialogImage {
  animation: rotate 4s linear infinite;
  width: 64px;
  height: auto;
  margin: auto;
  display: block;
  border-radius: 50%;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg)
  }
  40% {
    transform: rotate(90deg)
  }
  60% {
    transform: rotate(270deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

body {
  overflow-y: hidden;
}

:root {
    --c-white: #ffffff;
    --c-grey: #8e9194;
    --c-yellow: #eec643;
    --c-blue-light: rgba( 255, 255, 255, 0.3 );

    --c-text--primary: var(--c-white);
    --c-text--secondary: var(--c-grey);
    --c-text--strong: var(--c-yellow);
    --c-text--disabled: var(--c-blue-light);
}

.a-btn {}

.btn--primary {}

.btn--flat {}
.btn--flat:hover SPAN {
    color: #ffffff;
}

.dialog__content {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.dialog__label {
  display: block;
  color: var(--c-blue-light);
}


.dialog__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    column-gap: 16px;
}


.adminlist__row {
  cursor: pointer;
}

.adminlist__row:hover,
.adminlist__row:active {
  background-color: rgba( 153, 153, 153, 0.2);
}
