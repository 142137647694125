
/*{ margin:0; box-sizing: border-box; }/* Quick reset */
.wrapper {
    background:#00375D ;
    margin:0 auto;
    overflow-y: hidden;
    height: 100%;

    /* Direction of the items, can be row or column */
    flex-direction: column;
}
.wrapperContent {
  display: flex;
  flex-direction: column;
  /*https://stackoverflow.com/questions/37905749/flexbox-not-working-in-internet-explorer-11*()*/
  /*min-height: 100vh; */
  height: 100%;
}

main
{
  flex: 1 1;
  display: flex;
  width: 100%;
}

header
{
  height: 70px;
  background: #00375D;
  min-width: 1105px;
}

footer
{
  background: #00375D;
}

.side {
  /*max-width: 1450px;*/
}
.content {
    /*max-width: 1300px;*/
    min-width: 925px;
    /* Margin entfernt wegen Farbverlaeufen
    margin-left: 10px;*/
    height: inherit;
    width: inherit;
}
.logoutContent {
  width: -webkit-fill-available;
  width: fill;
  /*max-width: 1450px;*/
}
.sidebar{
    background: #002C4A;
    min-width: 180px;
    height: inherit;
}


.waitDialogDiv
{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  z-index: 1000;
  display: flex;
}
.waitDialogImage {
  animation: rotate 4s linear infinite;
  width: 64px;
  height: auto;
  margin: auto;
  display: block;
  border-radius: 50%;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg)
  }
  40% {
    transform: rotate(90deg)
  }
  60% {
    transform: rotate(270deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

body {
  overflow-y: hidden;
}

:root {
    --c-white: #ffffff;
    --c-grey: #8e9194;
    --c-yellow: #eec643;
    --c-blue-light: rgba( 255, 255, 255, 0.3 );

    --c-text--primary: var(--c-white);
    --c-text--secondary: var(--c-grey);
    --c-text--strong: var(--c-yellow);
    --c-text--disabled: var(--c-blue-light);
}

.a-btn {}

.btn--primary {}

.btn--flat {}
.btn--flat:hover SPAN {
    color: #ffffff;
}

.dialog__content {
  display: flex;
  flex-direction: column;
  grid-row-gap: 16px;
  row-gap: 16px;
}

.dialog__label {
  display: block;
  color: var(--c-blue-light);
}


.dialog__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    grid-column-gap: 16px;
    column-gap: 16px;
}


.adminlist__row {
  cursor: pointer;
}

.adminlist__row:hover,
.adminlist__row:active {
  background-color: rgba( 153, 153, 153, 0.2);
}

.footerLink a
{
    color: #FFFFFF;
}
.footerLink a:visited
{
    color: #FFFFFF;
}
.footerLink a:hover
{
    color: #FFFFFF;
}
.footerLink button
{
    color: #FFFFFF;
}
.footerLink button:hover
{
    color: #FFFFFF;
}

span.regular a:hover {
	color: #EEC643;
}

a:hover {
	/*color: #EEC643;*/
}
h1, h2 {
	display: block;
	height: 50px;
	font-size: 20px;
	padding-left: 20px;
	padding-top: 10px;
	font-weight: 400;
  color: #FFFFFF;
}
h2 {
	clear: both;
	margin-top: 40px;
	color: #EEC643;
	font-size: 20px;
	padding-left: 20px;
	padding-top: 10px;
	font-weight: 400;
}
h4 {
	margin-left: 20px;
	font-size: 20px;
	font-weight: 400;
  color: #FFFFFF;
}
p {
	font-size: 14px;
	line-height: 19px;
	margin-left: 20px;
	max-width: 580px;
	font-weight: 200;
}
.btn_blue {
	text-align: center;
	background: #7B98AB;
	border-radius: 2px;
	color: #ffffff;
	font-size: 14px;
	margin-top: 20px;
	padding: 10px 20px 10px 20px;
	text-decoration: none;
	display: block;
	float: left;
}
.btn_blue:hover {
	color: #ffffff;
	background: #6C8392;
}
.bildbreak {
	float: left;
	margin-left: 20px;
	margin-right: 20px;
	margin-bottom:20px;
}
.bildflow {
	float: left;
	margin-left: 5px;
	margin-right: 5px;
	margin-bottom:20px;
}
.section {
	width: 50%;
	min-width: 500px;
	float: left;
	margin-bottom: 40px;
}


@media (max-width:580px) {
	.bildbreak {
	float: none;
margin:0 auto 0 auto;
	display: block;}
	.btn_blue {margin:0 20px 0 20px;display: block;text-align: center;width:82%; float:none;}
}


@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url(/adminkonsole/static/media/Raleway-Regular.20d41940.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway-Italic';
  font-style: italic;
  font-weight: 400;
  src: url(/adminkonsole/static/media/Raleway-Italic.b88299f8.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway-Bold';
  font-style: bold;
  font-weight: 700;
  src: url(/adminkonsole/static/media/Raleway-Bold.88079335.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway-Bold-Italic';
  font-style: italic;
  font-weight: 700;
  src: url(/adminkonsole/static/media/Raleway-BoldItalic.17e4f15f.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway-Medium';
  font-style: normal;
  font-weight: 500;
  src: url(/adminkonsole/static/media/Raleway-Medium.b952c3c8.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway-Italic';
  font-style: italic;
  font-weight: 500;
  src: url(/adminkonsole/static/media/Raleway-MediumItalic.15a3a013.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway-Light';
  font-style: normal;
  font-weight: 300;
  src: url(/adminkonsole/static/media/Raleway-Light.ed645c2b.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway-Light-Italic';
  font-style: italic;
  font-weight: 300;
  src: url(/adminkonsole/static/media/Raleway-LightItalic.7f5fba2f.ttf) format('truetype');
}

div {
  letter-spacing: 0.45px;
}
input {
  letter-spacing: 0.45px;
}
span{
  letter-spacing: 0.45px;
}
.light {color: #FFFFFF; font-weight: 300;}
.regular {color: #FFFFFF; font-weight: 400;}
.medium { color: #FFFFFF; font-weight: 500; }
.mediumItalic { color: #FFFFFF; font-weight: 500; font-style: italic;}
.bo { color: #FFFFFF; font-style: bold;
font-weight: 700;}

p{color: #FFFFFF;
  letter-spacing: 0.45px;}

a{ color: #7B98AB;
letter-spacing: 0.45px;
text-decoration: none; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway';
  background:#00375D ;
  height: 100%;
}

html {
  height: 100%;
}

div#root {
  height: 100%;
}
/*IE11: disable X on inputfields*/
input[type=text]::-ms-clear{
  display:none;
}
/*IE11: disable ellipsis (...) */
input[type=text] {
  text-overflow: 'clip',
}
/*IE11: disable ellipsis (...) */
td{
  text-overflow: 'clip',
}

